<template>
  <div class="tokyo_tm_contact">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>Contact</span>
          <h3>Get in Touch</h3>
        </div>
      </div>
    </div>
    <!-- End Title -->

    <div class="success" v-if="success">
      Success!
    </div>

    <div class="fields">
      <ValidationObserver ref="observer">
        <form class="contact_form" @submit.prevent="onSubmit">
          <div class="first">
            <ul>
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <li>
                  <input
                    v-model="formData.name"
                    type="text"
                    placeholder="Name"
                    autocomplete="off"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>

              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <li>
                  <input
                    type="text"
                    rules="required|email"
                    v-model="formData.email"
                    placeholder="email"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>
              <ValidationProvider
                name="message"
                rules="required"
                v-slot="{ errors }"
              >
                <li>
                  <textarea
                    v-model="formData.message"
                    placeholder="Message"
                  ></textarea>
                  <span class="inpur-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>
            </ul>
          </div>
          <div>
            <vue-recaptcha ref="recaptcha" @verify="handleReaptcha" sitekey="6LfR8N4dAAAAALfzOdHf0f-pi1G1fSHoTaKocbwO" />
          </div>
          <div class="tokyo_tm_button">
            <button type="submit" class="ib-button" :class="{ inactive: busy || invalidRecaptcha }" v-bind:disabled="busy || invalidRecaptcha">
              <span v-if="busy">Wait a moment...</span>
              <span v-else>Send Message</span>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <!-- END FIELDS -->
  </div>
</template>

<script>
  import { VueRecaptcha } from 'vue-recaptcha'; 
  import { ValidationObserver } from "vee-validate";
  import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  import { collection, addDoc } from "firebase/firestore";
  import {db} from '../firebase/config'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      VueRecaptcha
    },
    data() {
      return {
        invalidRecaptcha: true,
        busy: false,
        success: false,
        formData: {
          name: "",
          email: "",
          message: "",
        },
      };
    },
    methods: {
      async onSubmit () {
        this.success = false;

        const isValid = await this.$refs.observer.validate();

        if (!isValid) {
            return;
        }

        if(!this.invalidRecaptcha)
          this.sendForm();
      },
      handleReaptcha (){
        this.invalidRecaptcha = false;
      },
      async sendForm(){
        try {
          this.busy = true;

          await addDoc(collection(db, "contacts"), this.formData);

          this.resetForm();
        } catch (error) {
          this.busy = false;
          this.success = false;
        }
      },
      resetForm () {
        this.formData = {
          name: "",
          email: "",
          message: "",
        };

        this.busy = false;
        this.success = true;
        this.invalidRecaptcha = true;

        this.$nextTick(() => {
          this.$refs.recaptcha.reset();
          this.$refs.observer.reset();
        });
      }
    },
  };
</script>

<style>
.success{
  padding: 10;
  font-weight: 800;
  color: green;
  margin-bottom: 10px;
}

.inactive{
  background-color: grey;
}

.ib-button{
  margin-top: 110px;
}
</style>