<template>
  <div class="tokyo_tm_about">
    <div class="about_image">
      <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-b5837.appspot.com/o/cover.jpeg?alt=media&token=c5a06b1d-b379-494e-a8fe-6a30cc869478" alt="about" />
    </div>

    <div class="description">
      <h3 class="name">Rodrigo Marinheiro &amp; Developer</h3>
      <div class="description_inner">
        <div class="left">
          <p>
            Hello, I am a developer based in Coimbra, Portugal and I'm happy to create and develop solutions for everyday problems.
            Can I help you? Please use the contact page to get in touch.
          </p>
          <div class="tokyo_tm_button">
            <button class="ib-button" @click="showModal">Read More</button>
          </div>
        </div>

        <div class="right">
          <ul>
            <li>
              <p><span>Birthday:</span>08.12.1990</p>
            </li>
            <li>
              <p><span>Age:</span>31</p>
            </li>
            <li>
              <p><span>Address:</span>Coimbra, Portugal</p>
            </li>
            <li>
              <p>
                <span>Email:</span
                ><a href="mailto:mail@gmail.com">rodrigo_marinheiro@outlook.com</a>
              </p>
            </li>
            <!-- <li>
              <p>
                <span>Phone:</span
                ><a href="tel:+770221770505">+77 022 177 05 05</a>
              </p>
            </li> -->
            <li>
              <p><span>Study:</span>Instituto Politécnico de Tomar</p>
            </li>
            <li>
              <p><span>Freelance:</span>Available</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div
        :class="{ 'modal-mask': isActive }"
        id="modal"
        @click="closeModal"
        v-if="isVisible"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
          @click.stop
        >
          <div class="modal-content">
            <div class="tokyo_tm_modalbox_about">
              <div class="close" @click="closeModal">
                <img
                  class="svg"
                  src="../assets/img/svg/cancel.svg"
                  alt="cancel-img"
                />
              </div>
              <div class="my_box">
                <div class="left">
                  <div class="about_title">
                    <h3>Programing Skills</h3>
                  </div>
                  <div class="tokyo_progress">
                    <div class="progress_inner">
                      <span
                        ><span class="label">ASP.NET MVC</span
                        ><span class="number">95%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:95%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                        ><span class="label">Flutter</span
                        ><span class="number">80%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:80%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                        ><span class="label">Xamarin.Forms</span
                        ><span class="number">75%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:75%"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right">
                  <div class="about_title">
                    <h3>Language Skills</h3>
                  </div>
                  <div class="tokyo_progress">
                    <div class="progress_inner">
                      <span
                        ><span class="label">Portuguese</span
                        ><span class="number">95%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:95%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                        ><span class="label">English</span
                        ><span class="number">50%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:50%"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="counter">
                <div class="about_title">
                  <h3>Fun Facts</h3>
                </div>
                <ul>
                  <li>
                    <div class="list_inner">
                      <h3>100+</h3>
                      <span>Projects Completed</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>40+</h3>
                      <span>Happy Clients</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>9K+</h3>
                      <span>Lines of Code</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      isActive: false,
      isVisible: false,
    };
  },
  methods: {
    showModal: function() {
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss"></style>
