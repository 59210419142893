import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'


const firebaseConfig = {
  apiKey: "AIzaSyCLsrBpKD9_JAaCR1VwiN946JLqWYNJmbY",
  authDomain: "portfolio-b5837.firebaseapp.com",
  projectId: "portfolio-b5837",
  storageBucket: "portfolio-b5837.appspot.com",
  messagingSenderId: "816358145313",
  appId: "1:816358145313:web:0d0f6091be26807c47d260",
  measurementId: "G-EZM08T1N4Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app)

export {db, analytics}
