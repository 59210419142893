<template>
  <div class="tokyo_tm_home">
    <div class="home_content">
      <div class="avatar">
        <div
          class="image avatar_img"
          :style="{ backgroundImage: 'url('+face+')' }"
        ></div>
      </div>
      <!-- End .avatar -->
      <div class="details">
        <h3 class="name">Rodrigo Marinheiro</h3>
        <p class="job">
          .net and flutter developer based in Portugal.
        </p>

        <Social />
      </div>
      <!-- End .details -->
    </div>
  </div>
</template>

<script>
  import Social from "./Social";
  export default {
    components: {
      Social,
    },
    data() {
      return {
        face: 'https://firebasestorage.googleapis.com/v0/b/portfolio-b5837.appspot.com/o/face.png?alt=media&token=f6c998f9-1290-45e0-9048-35750fc62d65'
      };
    },
  };
</script>

<style lang="scss"></style>
